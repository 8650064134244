<template>
    <div>
    <div id="page-user-edit" class="flex">
      <!-- Owner Details -->
      <div class="w-1/3 mr-4">
        <vs-alert
          color="danger"
          title="User Not Found"
          :active.sync="user_not_found"
        >
          <span>Owner record with id: {{ $route.params.ID }} not found. </span>
          <span>
            <span>Check </span>
            <router-link
              :to="{ name: 'page-user-list' }"
              class="text-inherit underline"
            >All Unit Owner</router-link>
          </span>
        </vs-alert>

        <vx-card class="mb-4" color="primary" elevation="2">
          <h3 style="padding-bottom: 20px;">{{ $t("UserInformation") }}</h3>
          <div class="mb-4">
            <!-- Displaying User Information with icons -->
            <div v-for="(value, key) in userData" :key="key" class="flex items-center mb-2">
              <i class="feather icon" :class="getIcon(key)" style="color: #2196F3;"></i>
              <span style="margin-right: 10px;">{{ value }}</span>
            </div>

          </div>
        </vx-card>
      </div>
      <!-- Unit Details and Payments -->
      <div class="w-2/3">
        <!-- Unit Details -->
        <div id="data-list-list-view" class="data-list-container mb-4">
          <vs-table ref="table" id="Scroll" style="padding-top: 5px; " :data="unitDetails">
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
              <h3>{{ $t("MaintenanceRequests") }}</h3>
            </div>
            <template slot="thead">
              <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="UnitModel">{{ $t("ServiceType") }}</vs-th>
              <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Name">{{ $t("TechnicalName") }}</vs-th>
              <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Status">{{ $t("RequestDate") }}</vs-th>

            </template>
            <template slot-scope="{ data }">
              <tbody>
                <tr v-for="(tr, indextr) in data" :key="indextr" :class="{ 'selected-row': tr.selected }" @click="toggleRow(tr)">
                  <vs-td><p class="product-name font-medium truncate">{{ tr.modelName }}</p></vs-td>
                  <vs-td><p class="product-name font-medium truncate">{{ tr.buildingName }}</p></vs-td>
                  <vs-td><p class="product-name font-medium truncate">{{ tr.levelName }}</p></vs-td>
                </tr>
              </tbody>
            </template>
          </vs-table>
        </div>

      </div>
    </div>
      <!-- Cards -->

      <h3 style="padding-right: 100px; padding-bottom: 15px;">{{ $t("RealEstateAds") }}</h3>
      <div class="card-container flex flex-wrap justify-center items-center">
        <div v-for="(card, index) in cards" :key="index" class="card bg-white shadow-lg rounded-lg p-6 m-4" style="width: 20%; height: 350px;">
          <i class="feather icon-home mr-2" style="color: #2196F3;"></i>
          <span class="card-heading text-xl font-bold mb-2" style="font-size: 10px; font-weight: bold;">{{ card.title }}</span>
          <div class="scrolling-content" >
            <template v-if="card.imageUrl">
              <div class="img-container w-100 h-100  flex items-center justify-center">
                <img :src="card.imageUrl" class="img-thumbnail" style="width: 150px; height: 150px;" />
              </div>
            </template>
            <div class="card-body text-gray-700 mb-4" title="Description">{{ truncatedDescription(card.body) }}</div>
            <div class="card-footer text-gray-500">
              <!-- Icons for location, number of rooms, and number of persons -->
              <div title="Location" class="flex items-center">
                <i  class="feather icon-map-pin mr-2" style="color: #2196F3;"></i>
                <span>{{ card.location }}</span>
              </div>
              <div title="Numper of Rooms" class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="24" style="color: #2196F3;">
                    <path fill="currentColor" d="M16 12c0-2.76-2.24-5-5-5s-5 2.24-5 5v1H2v8h2v-3h16v3h2v-8h-9v-1zM8 12c0-1.66 1.34-3 3-3s3 1.34 3 3v1H8v-1zm12 9h-2v-6H6v6H4v-4c0-1.66 1.34-3 3-3h10c1.66 0 3 1.34 3 3v4zm-8-9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                </svg>
                <span> {{ card.rooms }}</span>
              </div>
              <div class="flex items-center" title="Numper of Persons">
                <i class="feather icon-user mr-2" style="color: #2196F3;"></i>
                <span>{{ card.persons }}</span>
              </div>
              <div class="flex items-center" title="Details">
                <vs-button color="#141466" class="mr-4">
                  <i class="feather icon-info mr-2" style="color: white; font-size: 18px;"></i>
              </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--services management requests-->
      <div id="data-list-list-view" class="data-list-container mb-4">
        <vs-table ref="table" id="Scroll" style="padding-top: 5px; " :data="servicesdata">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <h3>{{ $t("RealEstateServicesrequests") }}</h3>
          </div>
          <template slot="thead">
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="UnitModel">{{ $t("request") }}</vs-th>
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Name">{{ $t("RequestType") }}</vs-th>
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Status">{{ $t("RequestDate") }}</vs-th>

          </template>
          <template slot-scope="{ data }">
            <tbody>
              <tr v-for="(tr, indextr) in data" :key="indextr" :class="{ 'selected-row': tr.selected }" @click="toggleRow(tr)">
                <vs-td><p class="product-name font-medium truncate">{{ tr.request }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate">{{ tr.requesttype }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate">{{ tr.requestdate }}</p></vs-td>
              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </template>

  <script>
  import VxCard from "../../components/vx-card/VxCard.vue";
  import { domain } from "@/gloabelConstant.js";
  //import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";

  export default {
    components: {
      VxCard
    },
    data() {
      return {
        baseURL: domain,
        user_not_found: false,
        activeTab: 0,
        OwnerPayments: {},
        showPayments: false,
        showDenialReason: false,
        denialReason: '',
        denySent: false,
        selectedRow: null,
        ownerData: null,
        userDatas: {
          ownerName: "محمد أحمد",
          address: "شارع الجمهورية، القاهرة",
          phoneNumber: "+123456789",
          Email: "JohnDoe@gmail.com",
          status: "Active",
          nationalCardNumber: "1234567890",

        },
        unitDetails: [
        { modelName: "سباك", buildingName: "احمد محمد", levelName: "2024-05-08" },
        { modelName: "كهربائى", buildingName: "محمد حسن", levelName: "2024-05-09"},
        { modelName: "نجار", buildingName: "ايمن ناصر", levelName: "2024-05-10" }
      ],
      servicesdata: [
        { request: "طلب تطوير عقارى", requesttype: " تطوير", requestdate: "2024-05-08" },
        { request: "طلب مساهمة عقارية", requesttype: " مساهمة", requestdate: "2024-05-08" },
        { request: "طلب تقييم عقارى", requesttype: "تقييم", requestdate: "2024-05-08" },
      ],
       cards: [
            {
              title: 'فيلا على البحر',
              body: 'تقدم هذه الفيلا في الساحل الشمالي إطلالة خلابة على البحر وجو هادئ محاط بالحدائق والزهور.',
              imageUrl: require("@/assets/images/servicesmanagmentdetails/areq1.jpg"),
              location: 'الساحل الشمالي',
              rooms: '10',
              persons: '10',
              area: '200 متر مربع',
              details: {
                'عدد الغرف': '10',
                'عدد الحمامات': '2',
                'المساحة': '200 متر مربع',
                'عدد الأشخاص': '10',
                'سنة البناء': '2023',
                'العنوان': 'الساحل الشمالي',
                'المحافظة': 'الإسكندرية'
              }
            },
            {
              title: 'شقة فخمة',
              body: 'تقدم هذه الشقة في الزمالك حياة فاخرة مع وسائل الراحة الحديثة وإطلالة مبهجة على المدينة.',
              imageUrl: require("@/assets/images/servicesmanagmentdetails/daqar2.jpg"),
              location: 'الزمالك',
              rooms: '4',
              persons: '6',
              area: '150 متر مربع',
              details: {
                'عدد الغرف': '4',
                'عدد الحمامات': '2',
                'المساحة': '150 متر مربع',
                'عدد الأشخاص': '6',
                'سنة البناء': '2022',
                'العنوان': 'الزمالك',
                'المحافظة': 'القاهرة'
              }
            },
            {
              title: 'منزل على الشاطئ',
              body: 'يقدم هذا المنزل على شاطئ البحر الأحمر إطلالات خلابة على البحر الأحمر وبيئة مريحة.',
              imageUrl: require("@/assets/images/servicesmanagmentdetails/kitchen.jpg"),
              location: 'الغردقة',
              rooms: '5',
              persons: '6',
              area: '180 متر مربع',
              details: {
                'عدد الغرف': '5',
                'عدد الحمامات': '3',
                'المساحة': '180 متر مربع',
                'عدد الأشخاص': '6',
                'سنة البناء': '2021',
                'العنوان': 'الغردقة',
                'المحافظة': 'البحر الأحمر'
              }
            },
            {
              title: 'منتجع في الجبال',
              body: 'تقدم هذه الشاليه الرائعة في قلب الجبال جو من السكينة والطبيعة الساحرة.',
              imageUrl: require("@/assets/images/servicesmanagmentdetails/bath3.jpeg"),
              location: 'جبال السخنة',
              rooms: '3',
              persons: '4',
              area: '120 متر مربع',
              details: {
                'عدد الغرف': '3',
                'عدد الحمامات': '1',
                'المساحة': '120 متر مربع',
                'عدد الأشخاص': '4',
                'سنة البناء': '2020',
                'العنوان': 'جبال السخنة',
                'المحافظة': 'السويس'
              }
            }
          ],
      };
    },
    computed:{
      OwnerDetails(){
        return this.$store.state.AdsManagementList.AdsManagements;
      },
      // unitDetails(){
      //   return this.$store.state.AdsManagementList.AdsManagements.unitDetails;
      // }
    },
    methods: {

      getIcon(key) {
        switch (key) {
          case "ownerName":
            return "icon-user";
          case "address":
            return "icon-map-pin";
          case "phoneNumber":
            return "icon-phone";
          case "Email":
            return "icon-mail";
          case "status":
            return "icon-check-circle";
          case "nationalCardNumber":
            return "icon-credit-card";
          default:
            return "";
        }
      }
    ,
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Adjust format as needed
      },
      openConfirm(data) {
        this.rowDataForDelete = data;
        const confirmText = data.isPaid ? this.$t("هل انت متأكد انك تريد دفع هذا المستحق") : this.$t("هل انت متأكد انك تريد سحب هذا المستحق");
        this.$vs.dialog({
          type: "confirm",
          color: "success",
          title: this.$t("PaymentConfirmation"),
          text: confirmText,
          accept: this.acceptAlert,
          acceptText: this.$t("Confirm"),
          cancelText: this.$t("Cancel"),
        });
      },
      acceptAlert() {
        this.togglePayment(this.rowDataForDelete);
        this.$vs.notify({
          color: "success",
          title: "دفع المستحق",
          text: this.$t("تم دفع هذا المستحق بنجاح"),
        });
      },
      togglePayment(data) {
        const Id = data.id;
        //const isPaid = !data.isPaid;
        this.$store.dispatch("AdsManagementList/Paid", Id).then(() => {
          const ID = this.$route.params.ID;
          this.$store.dispatch("AdsManagementList/GetItemByID", ID);
          this.showPayments = true; // Show the payments table
        });
      },
      toggleRow(row) {
        if (this.selectedRow === row) {
          return; // Do nothing if the clicked row is already selected
        }

        if (this.selectedRow) {
          this.selectedRow.selected = false; // Deselect the currently selected row
        }

        this.selectedRow = row;
        row.selected = true;
      },
      acceptJoining(data) {
        var Id =  data.id;
        this.$store
            .dispatch("AdsManagementList/ApproveOrDenayUnitOwner", Id)
            .then(() => {
              const Id = data.unitID;
              this.$store
                .dispatch("AdsManagementList/GetUnitPayments", Id)
                .then(() => {
                  this.approvedsuccess();
                });
            });
      },

      approvedsuccess() {
        this.$vs.notify({
          color: "success",
          title: "تم قبول طلب الانضمام بنجاح",
          //text: this.$t("ApprovedSuccessfully"),
        });
      },
      denyJoining() {
        // Show denial reason input
        this.showDenialReason = true;
      },
      sendDenialReason() {
        // Send denial reason to backend
        console.log('Sending denial reason:', this.denialReason);
        // Hide the denial reason text area after sending
        this.showDenialReason = false;
        // Clear the denial reason
        this.denialReason = '';
        this.denySent = true;
      },
      GetUnitPayments(data) {
        const Id = data.unitID;
        this.$store
          .dispatch("AdsManagementList/GetUnitPayments", Id)
          .then((res) => {
            this.OwnerPayments = res.data.data;
            this.showPayments = true;
            this.toggleRow(data);
          });
      },
      truncatedDescription(description) {
        const words = description.split(' ');
        if (words.length > 5) {
          return words.slice(0, 5).join(' ') + '...';
        }
        return description;
      }
    },
    created() {
      this.ownerData = JSON.parse(this.$route.params.data);
    },
  };
  </script>

  <style scoped>
  .w-1\3 {
    width: 33.33%;
  }

  .w-2\3 {
    width: 66.66%;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  .toggle-switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  .custom-textarea {
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
    height: 100px;
    resize: none;
  }

  .custom-textarea:focus {
    outline: none;
    border-color: red;
  }
  .selected-row {
    background-color: #f0f0f0;
    cursor: pointer;
  }

  .selected-row:hover {
    background-color: #e0e0e0;
  }
  .img-thumbnail {
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 8px; /* Add rounded corners */
  }
  </style>
